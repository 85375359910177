<template>
  <pz-form-flujo
    v-model="formulario_garante['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form_garante.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario_garante"
      status-icon
      :rules="rules_formulario_garante"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="nombres"
            :label="$t('forms_flujo.form1_persona.SP.nombre')"
            :error="errors.get('nombres')"
          >
            <el-input
              ref="nombres"
              v-model.number="formulario_garante.nombres"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.nombre')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="apellidos"
            :label="$t('forms_flujo.form1_persona.SP.apellidos')"
            :error="errors.get('apellido_paterno')"
          >
            <el-input
              ref="apellidos"
              v-model.number="formulario_garante.apellidos"
              :type="'text'"
              :placeholder="$t('forms_flujo.form1_persona.SP.apellidos')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
            <pz-identity-document
                ref="identity"
                v-model="formulario_garante.identity"
                prop="identity"
                type="person"
                :type-label="$t('forms_flujo.form_garante.UY.identity_doc')"
                :number-label="$t('forms_flujo.form_garante.UY.identity_doc')"
                type-hidden
            />
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="direccion"
            :label="$t('forms_flujo.form_garante.UY.address')"
            :error="errors.get('direccion')"
          >
            <el-input
              v-model="formulario_garante.direccion"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form_garante.UY.address')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_nacimiento"
            :label="$t('forms_flujo.form_garante.UY.born_date')"
            :error="errors.get('fecha_nacimiento')"
          >
            <el-date-picker
              v-model="formulario_garante.fecha_nacimiento"
              type="date"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :picker-options="pickerOptions1"
              :placeholder="$t('forms_flujo.form_garante.UY.born_date')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_ingreso"
            :label="$t('forms_flujo.form_garante.UY.fecha_ingreso')"
            :error="errors.get('fecha_ingreso')"
          >
            <el-date-picker
              v-model="formulario_garante.fecha_ingreso"
              type="date"
              :picker-options="pickerOptions1"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              :placeholder="$t('forms_flujo.form_garante.UY.fecha_ingreso')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="ingreso_mensual"
            :label="$t('forms_flujo.form_garante.UY.ingreso_mensual')"
            :error="errors.get('ingreso_mensual')"
          >
            <el-input
              v-model.number="formulario_garante.ingreso_mensual"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form_garante.UY.ingreso_mensual')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
          class="margin"
        >
          <el-form-item
            prop="telefono"
            :label="$t('forms_flujo.form_garante.UY.phone')"
            :error="errors.get('telefono')"
          >
            <el-input
              v-model="formulario_garante.telefono"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form_garante.UY.phone')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="provincia"
            :label="$t('forms_flujo.form_garante.UY.state')"
            :error="errors.get('provincia')"
          >
            <el-select
              v-model="formulario_garante.provincia"
              clearable
              :placeholder="$t('forms_flujo.form_garante.UY.state')"
            >
              <el-option
                v-for="item in departamentos"
                :key="item.departamento"
                :label="item.departamento"
                :value="item.departamento"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="relacion_laboral"
            :label="$t('forms_flujo.form_garante.UY.relacion_laboral')"
            :error="errors.get('relacion_laboral')"
          >
            <el-select
              v-model="formulario_garante.relacion_laboral"
              clearable
              :placeholder="$t('forms_flujo.form_garante.UY.relacion_laboral')"
            >
              <el-option
                v-for="item in employment_relations"
                :key="item.id"
                :label="item.relacion"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_empresa"
            :label="$t('forms_flujo.form_garante.UY.company_phone')"
            :error="errors.get('telefono_empresa')"
          >
            <el-input
              v-model="formulario_garante.telefono_empresa"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form_garante.UY.company_phone')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>

<script>
import {EspacioBlanco, required, type} from "@/commons/utils/Rules";
import {getDepartamentos, getRelacionesLaborales} from "@/routes/api/resources";
import baseGarante from '../baseGarante'
import {mapGetters} from "vuex";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";

export default {
    name: 'PzGaranteFormUy',
    components: {PzFormFlujo, PzIdentityDocument},
    mixins: [baseGarante],
    provide: function () {
        return {
            myForm: this
        }
    },
    data() {
        var checCIGarante = (rule, value, callback) => {
            if (this.form_product.cedula === this.formulario_garante.identity.number) {
                return callback(new Error(this.$t('forms_flujo.form_garante.UY.error_applicant')));
            } else {
                return callback();
            }
        };
        return {
            employment_relations: [],
            departamentos: [],
            formulario_garante: {
                nombres: '',
                apellidos: '',
                telefono: '',
                fecha_nacimiento: '',
                direccion: '',
                provincia: '',
                relacion_laboral: '',
                telefono_empresa: '',
                fecha_ingreso: '',
                ingreso_mensual: '',
                url_trackeo: '',
                tipo_documento: 1,
                identity: {document: 1},
            },
            disable: true,
            rules_formulario_garante: {
                'identity.document': [required('')],
                'identity.number': [required(''),EspacioBlanco(), {validator: checCIGarante, trigger: 'blur'}],
                nombres: [required('')],
                apellidos: [required('')],
                telefono:
                    [
                        required('')
                    ],
                fecha_nacimiento:
                    [
                        required('')
                    ],
                direccion:
                    [
                        required('')
                    ],
                provincia:
                    [
                        required('')
                    ],
                relacion_laboral:
                    [
                        required('')
                    ],
                telefono_empresa:
                    [
                        required('')
                    ],
                fecha_ingreso:
                    [
                        required('')
                    ],
                ingreso_mensual:
                    [
                        required(''),
                        type({field: '', type: 'number'}),
                    ],
            },
            pickerOptions1: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
        }
    },
    computed: {
        ...mapGetters(['product', 'form_product', 'current_step']),
    },
    created: function () {
        this.$emit('step', 'FormularioGarante');
    },
    beforeMount() {
        this.reference = 'formulario_garante';
        getRelacionesLaborales().then((res) => {
            var employment_relations = res.data;
            this.employment_relations = employment_relations.filter(function (elem) {
                var proibid = ['Pensionista', 'Jubilado', 'Otros', 'Desempleado', 'No se sabe'];
                if (!(proibid.indexOf(elem.relacion.toString()) !== -1)) {
                    return elem;
                }
            });
        }).catch(() => {
            this.employment_relations = [];
        });
        getDepartamentos().then((res) => {
            this.departamentos = res.data;
        }).catch(() => {
            this.departamentos = [];
        });
    },
    mounted() {

    },
    methods: {}
}
</script>