<script>
    import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";
    import {resendVerificationCode} from "@/routes/api/views/flujo";

    export default {
        name: 'PzBaseValidarTelefono',
        components: {},
        mixins: [baseFormFlujo],
        provide: function () {
            return {
                myForm: this
            }
        },
        data() {
            return {
                reference: 'formulario_validarTelefono',
            }
        },
        created() {
            this.resenCode();
        },
        methods: {
            handleResend() {
                this.resenCode();
                this.$store.dispatch('app/setMessage', this.$t('validar_telefono.resend_code'))
            },
            resenCode() {
                this.$store.dispatch('loader/up',{trigger:this.$options.name});
                resendVerificationCode(this.loan).
                    then(() => {
                        this.$store.dispatch('loader/down',{trigger:this.$options.name});
                    }).
                    catch((error) => {
                        this.$store.dispatch('app/catchErrors', {_this: this, error: error})
                    });

            },

        }
    }
</script>


