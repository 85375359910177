<script>
    import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";

    export default {
        name: 'PzBaseGarante',
        mixins: [baseFormFlujo],
        props: {
            persona: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                reference: 'formulariogarante',
            }
        },
        created() {
        },
        methods: {},
    }
</script>


